module.exports={
  "en": {},
  "sv": {
    "Nothing here": "Inget hÃ¤r",
    "Read more": "LÃ¤s mer",
    "Toggle menu": "Visa/dÃ¶lj menu",
    "View case": "Visa case",
    "Cases": "VÃ¥ra Case",
    "Homepage": "Hemsida",
    "Loading": "Laddar"
  }
}
